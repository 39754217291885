import React, { useState, useEffect } from "react";
import imgEntrada from "../../img/entrada.jpg";
import clienteAxios from "../admin/config/axios";

const Horarios = () => {
  const [horarios, modificarHorarios] = useState({
    apertura: "",
    cierre: "",
  });

  useEffect(() => {
    clienteAxios.get("/api/horarios").then(function (res) {
      modificarHorarios({
        ...horarios,
        apertura: res.data.apertura,
        cierre: res.data.cierre,
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg margen-arriba">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="datos-horarios">
              <h2 className="mb-5">Horarios del Parque</h2>
              <i className="fas fa-clock mb-3 display-4 icono-horario"></i>
              <p style={{ marginBottom: "5" }}>
             
                El parque se encuentra abierto todos los días<br></br> de {horarios.apertura} a {horarios.cierre} Hs.{" "}
                <br></br>
              </p>
          
            </div>
          </div>
          <div className="col-md-6">
            <img className="img-horarios" src={imgEntrada} alt="Horarios" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Horarios;

import React from 'react';

const ComoLlegar = () => {
    return (
        <div className="bg margen-arriba">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="datos-ubi">
                        <h2>¿Como Llego al Parque?</h2>
                        <p>El Laberinto se encuentra en Las Toninas, unos de los principales destinos turísticos del Partido de La Costa, ubicado sobre la Av. 7 y esquina 16, a solo 5 minutos de la terminal. Al encontrarse en el corazón de las Toninas, su ubicación es privilegiada... no podés dejar de visitarnos!!</p>
                        
                        <ul className="social-network social-circle">
                            <li><a href="/#" className="icoFacebook" title="En Auto"><i className="fas fa-car"></i></a></li>
                            <li><a href="/#" className="icoYoutube" title="Caminando"><i className="fas fa-walking"></i></a></li>
                            <li><a href="/#" className="icoInsta" title="En colectivo"><i className="fas fa-bus"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <iframe title="Mapa" width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=laberinto%20las%20toninas+(Laberinto%20Las%20Toninas)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div>
            </div>
        </div>
        </div>
     );
}
 
export default ComoLlegar;
import React from 'react';
import { Link } from 'react-router-dom';
import logoCalidad from '../img/sponsors/calidad.svg';
import logoCalTuristica from '../img/sponsors/calidad-turistica.svg';
import logomuni from '../img/sponsors/munilacosta.svg';
import logoradio from '../img/sponsors/logo_fmlaberinto_nuevo.png';
import logoMinisterio from '../img/sponsors/ministerio.svg';

const Sponsors = () => {
    return ( 
        <div className="principal">
            <div className="container-fluid">
           
                <div className="row">
                
                    <div className="col-md-1"></div>
                    <div className="col-md-2 col-sm-4 col-4 my-auto">
                        <Link to={"/"} ><img className="loguitos" alt="Compromiso de Calidad Turistica" src={logoCalidad} /></Link>
                    </div>
                    <div className="col-md-2 col-sm-4 col-4 my-auto" >
                        <Link to={"/"} ><img className="loguitos" alt="La costa Calidad Turistica" src={logoCalTuristica} /></Link>
                    </div>
                    <div className="col-md-2 col-sm-4 col-4 my-auto">
                        <a href="https://www.lacosta.gob.ar/turismo/" target="_blank" rel="noopener noreferrer"><img className="loguitos" alt="Secretaria de Turismo La costa" src={logoMinisterio} /></a>
                    </div>
                    <div className="col-md-2 col-sm-6 col-6 my-auto">
                        <a href="https://lacosta.gob.ar/" target="_blank" rel="noopener noreferrer"><img className="loguitos" alt="La costa" src={logomuni} /></a>
                    </div>
                    <div className="col-md-2 col-sm-6 col-6 my-auto">
                        <a href="https://fmlaberinto.com.ar/" target="_blank" rel="noopener noreferrer">
                            <img className="loguitos logo-laberinto" alt="Fm Laberinto" src={logoradio} />
                        </a>
                    </div>
                    <div className="col-md-1"></div>
                
                </div>
           
            </div>
        </div>
     );
}
 
export default Sponsors;
import React from 'react';
import { Link } from 'react-router-dom';

const Card = (props) => {

    return (
        
            <div className="card card--section" >
                <img style={{objectFit: 'cover',height: '240px'}} src={props.urlImg} className="card-img-top" alt="Imagen Card" />
                <div className="card-body">
                    <h5 className="card-title">{props.titulo}</h5>
                    <p className="card-text">{props.descripcion}</p>
                    <div className="container-btn">
                    <Link to={props.url} className="btn">Ver mas...</Link>
                    </div>
                    
                </div>
                
            </div>
       
            
     );
}
 
export default Card;